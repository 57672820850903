<template>
  <div class="main" id="gallery">
    <div class="main__container">
      <h2>GALLERY</h2>
      <!-- Gallery desktop -->
      <div class="main__container__gallery">
        <div class="row">
          <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
            <a
              href="https://trung-pianist.netlify.app/img/piano.33bdc7fb.jpg"
              data-toggle="lightbox"
              data-gallery="gallery"
            >
              <img
                src="../assets/images/gallery/piano.jpg"
                class="w-100 shadow-1-strong rounded mb-4 hover-shadow"
                alt="Piano"
              />
            </a>
            <a
              href="https://trung-pianist.netlify.app/img/img-black.d4c84b11.jpg"
              data-toggle="lightbox"
              data-gallery="gallery"
            >
              <img
                src="../assets/images/gallery/img-black.jpg"
                class="w-100 shadow-1-strong rounded mb-4 hover-shadow"
                alt="Portrait"
              />
            </a>
          </div>

          <div class="col-lg-4 mb-4 mb-lg-0">
            <a
              href="https://trung-pianist.netlify.app/img/portrait.78d95c23.jpg"
              data-toggle="lightbox"
              data-gallery="gallery"
            >
              <img
                src="../assets/images/gallery/portrait.jpg"
                class="w-100 shadow-1-strong rounded mb-4 hover-shadow"
                alt="Portrait closeup"
              />
            </a>

            <a
              href="https://trung-pianist.netlify.app/img/piano-landscape.80ee85bf.jpg"
              data-toggle="lightbox"
              data-gallery="gallery"
            >
              <img
                src="../assets/images/gallery/piano-landscape.jpg"
                class="w-100 shadow-1-strong rounded mb-4 hover-shadow"
                alt="Piano"
              />
            </a>
          </div>

          <div class="col-lg-4 mb-4 mb-lg-0">
            <a
              href="https://trung-pianist.netlify.app/img/piano-closeup.6691ca07.jpg"
              data-toggle="lightbox"
              data-gallery="gallery"
            >
              <img
                src="../assets/images/gallery/piano-closeup.jpg"
                class="w-100 shadow-1-strong rounded mb-4 hover-shadow"
                alt="Piano performance"
              />
            </a>

            <a
              href="https://trung-pianist.netlify.app/img/portrait-2.32916e9a.jpg"
              data-toggle="lightbox"
              data-gallery="gallery"
            >
              <img
                src="../assets/images/gallery/portrait-2.jpg"
                class="w-100 shadow-1-strong rounded mb-4 hover-shadow"
                alt="Portrait"
              />
            </a>
          </div>
          <div class="col-lg-4 mb-4 mb-lg-0">
            <a
              href="https://trung-pianist.netlify.app/img/stairs.53c46013.jpg"
              data-toggle="lightbox"
              data-gallery="gallery"
            >
              <img
                src="../assets/images/gallery/stairs.jpg"
                class="w-100 shadow-1-strong rounded mb-4 hover-shadow"
                alt="Piano performance"
              />
            </a>
          </div>
          <div class="col-lg-4 mb-4 mb-lg-0">
            <a
              href="https://trung-pianist.netlify.app/img/piano-artsy.dbdc96a2.jpg"
              data-toggle="lightbox"
              data-gallery="gallery"
            >
              <img
                src="../assets/images/gallery/piano-artsy.jpg"
                class="w-100 shadow-1-strong rounded mb-4 hover-shadow"
                alt="Piano performance"
              />
            </a>
          </div>
          <div class="col-lg-4 mb-4 mb-lg-0">
            <a
              href="https://trung-pianist.netlify.app/img/balcony.83a96cc7.jpg"
              data-toggle="lightbox"
              data-gallery="gallery"
            >
              <img
                src="../assets/images/gallery/balcony.jpg"
                class="w-100 shadow-1-strong rounded mb-4 hover-shadow"
                alt="Piano performance"
              />
            </a>
          </div>
        </div>
      </div>
      <!-- End of Gallery Desktop -->

      <!-- Mobile Carousel -->
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              class="d-block w-100"
              src="../assets/images/gallery/piano.jpg"
              alt="First slide"
            />
          </div>

          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="../assets/images/gallery/piano-landscape.jpg"
              alt="Second slide"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="../assets/images/gallery/piano-closeup.jpg"
              alt="Third slide"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="../assets/images/gallery/stairs.jpg"
              alt="Fourth slide"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="../assets/images/gallery/piano-artsy.jpg"
              alt="Fifth slide"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="../assets/images/gallery/balcony.jpg"
              alt="Sixth slide"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="../assets/images/gallery/portrait-2.jpg"
              alt="Seventh slide"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="../assets/images/gallery/img-black.jpg"
              alt="Eigth slide"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block w-100"
              src="../assets/images/gallery/portrait.jpg"
              alt="Ninth slide"
            />
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <!-- End of Mobile Carousel -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Spotify",
  props: {},
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  background-color: #000000;
  &__container {
    iframe {
      width: 100%;
      max-width: 1100px;
      display: block;
      margin: 0 auto;
    }
    .carousel {
      display: none;
    }
  }
}

@media (max-width: $desktop-breakpoint-min) {
  .main {
    &__container {
      &__gallery {
        display: none;
      }
      .carousel {
        display: block;
      }
    }
  }
}
</style>
